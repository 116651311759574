<template>
    <middle-loading :loading="loading" v-if="loading" />

    <sermon-form v-else :sermon="sermon" @ok="handleOk"></sermon-form>
</template>

<script>
import SermonForm from './SermonForm'
import { getSermon } from '@/api/sermon'
import MiddleLoading from '@/components/MiddleLoading'

export default {
    name: 'SermonEdit',
    components: { SermonForm, MiddleLoading },
    data () {
        return {
            loading: true,
            sermon: {}
        }
    },
    methods: {
        handleOk () {
            setTimeout(() => {
                this.$router.push({ path: '/sermon' })
            }, 1000)
        }
    },
    created() {
        const id = this.$route.params.id
        getSermon(id).then(res => {
            this.sermon = res.data
        }).finally(() => {
            this.loading = false
        })
    }
};
</script>
